import styled, { css } from 'styled-components'
import { Breakpoints } from './breakpoints'

const getSize = (source: any, size: string) => {
  if (Object.prototype.hasOwnProperty.call(source, size)) {
    return source[size]
  }
  return null
}

const containerMaxSizes = css`
  ${Object.keys(Breakpoints.containerSizes).map(
    (size) => css`
      @media screen and (max-width: ${getSize(Breakpoints, size)}) {
        max-width: ${getSize(Breakpoints.containerSizes, size)};
        ${size === 'sm'
          ? css`
              overflow: hidden;
            `
          : ''}
      }
    `,
  )}
`

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 2rem;
  width: 100%;
  padding: 0 0.8rem;
  margin: 0 auto 5rem auto;
  box-sizing: border-box;

  @media screen and (min-width: 1537px) {
    max-width: 1440px;
  }

  ${containerMaxSizes}
`
