import styled from 'styled-components'
import { Colors } from '../../styles'

export const View = styled.div`
  margin: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  * {
    stroke: ${Colors.gray._300};
  }
`
