import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import i18n from 'i18next'

interface IHttpOptions {
  baseURL?: string
  ignoreAuth?: boolean
}

type ClientHttpFactory = (opts?: IHttpOptions) => AxiosInstance

export const http: ClientHttpFactory = ({ baseURL, ignoreAuth } = {}) => {
  const config: AxiosRequestConfig = {
    baseURL: baseURL || `${process.env.REACT_APP_API_URL}`,
    headers: {
      common: {
        locale: i18n.language ?? 'pt',
      },
    },
  }

  if (!ignoreAuth) {
    const token = localStorage.getItem('RCSP_ACCESS_TOKEN')
    if (token) {
      config.headers.common.Authorization = `Bearer ${token}`
    }
  }
  return axios.create(config)
}
