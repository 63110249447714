import { FC, ReactNode } from 'react'
import { TD, TR, View, TdProps } from './styles'

export interface TableColumn extends TdProps {
  key?: number
  type: 'text' | 'element'
  value?: string
  element?: ReactNode
  colSpan?: number
  as?: 'th'
  label?: string
}

export interface TableRow {
  link: string
  columns: TableColumn[]
  isDisabled?: boolean
}

interface TableProps {
  header: TableColumn[]
  rows: TableRow[]
}

const columnRender = {
  text: function TextColumn({ value, label, ...rest }: TableColumn) {
    return (
      <TD data-label={label} {...rest}>
        {value}
      </TD>
    )
  },
  element: function ElementColumn({ element, label, ...rest }: TableColumn) {
    return (
      <TD data-label={label} {...rest}>
        {element}
      </TD>
    )
  },
}

export const Table: FC<TableProps> = ({ header, rows }) => {
  return (
    <View>
      <thead>
        <tr>
          {header.map((column, colIndex) =>
            columnRender[column.type]({ ...column, key: colIndex, as: 'th' }),
          )}
        </tr>
      </thead>
      <tbody>
        {rows.map((row, rowIndex) => (
          <TR
            key={rowIndex}
            onClick={() => {
              if (!row.isDisabled) window.open(row.link, '_blank')?.focus()
            }}
            isDisabled={!!row.isDisabled}
          >
            {row.columns.map((column, colIndex) =>
              columnRender[column.type]({ ...column, key: colIndex }),
            )}
          </TR>
        ))}
      </tbody>
    </View>
  )
}
