import useSWR, { SWRResponse } from 'swr'
import { http } from '../services/http'

interface IUseFetchOptions {
  url: string
  fetcher?: (params: any) => Promise<any>
  initialData?: any
}

type UseFetchType = <Data = any, Error = any>(
  useFetchOpts: IUseFetchOptions,
) => SWRResponse<Data, Error>

export const useFetch: UseFetchType = ({ url, fetcher, initialData }) => {
  const actualFetcher =
    fetcher ||
    (async (params) => {
      const response = await http().get(params)
      return response.data
    })

  return useSWR(url, actualFetcher, {
    initialData,
    revalidateOnFocus: false,
    shouldRetryOnError: false,
  })
}
