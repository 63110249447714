import { FC } from 'react'
import { View } from './styles'

import animationData from '../../assets/animations/loader.json'
import Lottie from 'react-lottie'

interface SimpleLoadingProps {
  size?: 'lg' | 'md'
}

const lottie = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid',
  },
}

const availableSizes = {
  lg: '20.0rem',
  md: '12.0rem',
}

export const SimpleLoading: FC<SimpleLoadingProps> = ({ size, ...rest }) => (
  <View {...rest}>
    <Lottie options={lottie} height={availableSizes[size ?? 'lg']} />
  </View>
)
