import { FC } from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import { CheckCircle, ChevronRight } from 'react-feather'
import { Head } from '../../components/layout/Head'
import { Table, TableColumn, TableRow } from '../../components/table'
import { H6, P2, P3 } from '../../styles'
import { Container } from '../../styles/grid'
import { Body, View, Wrapper } from './styles'
import { useFetch } from '../../helpers/useFetch'
import { SimpleLoading } from '../../components/SimpleLoading'
import { Empty } from '../../components/empty'
import { useAuth } from '../../contexts/auth'
import { format } from 'date-fns'

interface SurveyInfo {
  company: string
  survey: string
  link: string
  // eslint-disable-next-line camelcase
  publish_date: string
  // eslint-disable-next-line camelcase
  answered_at: string
}

const getParsedDate = (date: string): string => {
  try {
    return format(new Date(date), 'dd/MM/yyyy')
  } catch {
    return '-'
  }
}

const toTableRow = (
  {
    company,
    survey,
    link,
    publish_date: publishDate,
    answered_at: answeredAt,
  }: SurveyInfo,
  t: TFunction<'translation'>,
): TableRow => ({
  link,
  isDisabled: answeredAt !== null,
  columns: [
    {
      type: 'text',
      value: company,
      size: 'xs',
      label: t('home.table.company'),
    },
    { type: 'text', value: survey, label: t('home.table.survey') },
    {
      type: 'text',
      value: getParsedDate(publishDate),
      size: 'xxs',
      label: t('home.table.publish_date'),
    },
    {
      type: 'element',
      element: answeredAt !== null ? <CheckCircle /> : <ChevronRight />,
      size: 'xxs',
      align: 'right',
      label:
        answeredAt !== null ? t('home.table.answered') : t('home.table.open'),
    },
  ],
})

export const HomePage: FC = () => {
  const { t } = useTranslation()
  const { user } = useAuth()

  const { data: userSurveys, isValidating } = useFetch({ url: `/surveys` })

  const shouldShowLoader = userSurveys === undefined && isValidating

  const tableData =
    userSurveys?.map((survey: any) => toTableRow(survey, t)) ?? []

  const tableHeader: TableColumn[] = [
    { type: 'text', value: t('home.table.company'), size: 'xs' },
    { type: 'text', value: t('home.table.survey') },
    { type: 'text', value: t('home.table.publish_date'), colSpan: 2 },
  ]

  return (
    <View>
      <Head />
      <Container>
        <Body>
          <P2>{user?.name},</P2>
          <P2>{t('home.info1')}</P2>
          <P2 dangerouslySetInnerHTML={{ __html: t('home.info2') }}></P2>
          <P2>{t('home.info3')}</P2>
          <H6>{t('home.available_surveys')}</H6>
          <Wrapper>
            {shouldShowLoader ? (
              <SimpleLoading />
            ) : tableData.length ? (
              <Table header={tableHeader} rows={tableData} />
            ) : (
              <Empty title={t('home.no-surveys')} />
            )}
          </Wrapper>
          <P3>{t('home.footer')}</P3>
        </Body>
      </Container>
    </View>
  )
}
