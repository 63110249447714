import { FC, ReactNode } from 'react'
import { AlignLeft } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { H4 } from '../../styles'
import { View } from './styles'

interface EmptyProps {
  icon?: ReactNode
  title?: string
}

export const Empty: FC<EmptyProps> = ({ icon, title }) => {
  const { t } = useTranslation()
  return (
    <View>
      <span>
        <span>{icon}</span>
      </span>
      <H4>{title || t('empty.title')}</H4>
    </View>
  )
}

Empty.defaultProps = {
  icon: <AlignLeft />,
}
