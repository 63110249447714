export interface IAction<T = any> {
  type: string
  payload?: T
}

export interface IHandlers<T = any, P = any> {
  [K: string]: (state: T, action: IAction<P>) => T
}

export const createReducer =
  <T = any, P = any>(initialState: T, handlers: IHandlers<T, P>) =>
  (state = initialState, action: IAction<P>) => {
    const { type } = action
    if (!Object.prototype.hasOwnProperty.call(handlers, type)) {
      return state
    }
    return handlers[type](state, action)
  }
