import styled from 'styled-components'
import { Colors, H6, P2, P3, Typography } from '../../styles'
import { Layout } from '../../styles/layout'

export const View = styled(Layout)``

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  grid-column-end: span 12;
  color: ${Colors.gray._500};

  ${P2}:first-child {
    margin-bottom: ${Typography.size.s1};
  }

  ${H6} {
    margin-top: ${Typography.size.l1};
    color: ${Colors.gray._600};
  }

  ${P3} {
    margin-top: ${Typography.size.s1};
    text-align: center;
  }
`
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: calc(100vh - 322px);
`
