import { rgba } from 'polished'
import styled from 'styled-components'
import { Colors, H4, Typography } from '../../styles'

export const View = styled.div`
  display: flex;
  margin-top: 4.8rem;
  align-items: center;
  flex-direction: column;

  > span,
  > span > span {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: ${rgba(Colors.gray._500, 0.05)};
  }

  > span {
    width: 12rem;
    height: 12rem;
    > span {
      width: 8.4rem;
      height: 8.4rem;
    }
    svg {
      color: ${Colors.gray._500};
      width: 4.8rem;
      height: 4.8rem;
    }
  }

  ${H4} {
    color: ${Colors.gray._500};
    margin: 3.2rem 0 1.2rem 0;
    text-align: center;
    font-weight: ${Typography.weight.semiBold};
  }
`
