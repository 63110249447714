import { FC, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

export const RedirectToLogin: FC = () => {
  const { pathname } = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    navigate(`/entrar?to=${pathname}`, { replace: true })
  }, [])

  return null
}
