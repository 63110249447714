import { createContext, FC, useContext, useEffect, useReducer } from 'react'
import { useFetch } from '../helpers/useFetch'
import { LoadingPage } from '../pages/Loading'
import {
  IAuthContextState,
  INITIAL_STATE,
  reducer,
  actions,
} from '../reducers/auth-context'

interface IAuthContext extends IAuthContextState {
  evaluateAuth: () => void
  logout: () => void
}

const AuthContext = createContext<IAuthContext | null>(null)

export const useAuth = () => {
  const context = useContext(AuthContext)
  if (!context) {
    throw Error('Trying to useAuth outside AuthContext')
  }
  return context
}

export const AuthProvider: FC = ({ children }) => {
  const [contextState, dispatch] = useReducer(reducer, INITIAL_STATE)

  const {
    data: user,
    mutate,
    isValidating,
    error,
  } = useFetch({ url: '/users' })

  const updateUser = (user: any) =>
    dispatch({
      type: actions.UPDATE_USER,
      payload: user,
    })

  const setAuthenticated = (isAuthenticated: boolean) =>
    dispatch({ type: actions.SET_AUTHENTICATED, payload: isAuthenticated })

  useEffect(() => {
    if (error?.response?.status === 401) {
      localStorage.removeItem('RCSP_ACCESS_TOKEN')
    }
  }, [error])

  useEffect(() => {
    setAuthenticated(!!user)
    updateUser(user || null)
  }, [user])

  const evaluateAuth = () => mutate()

  const logout = async () => {
    localStorage.removeItem('RCSP_ACCESS_TOKEN')
    await mutate(null, false)
    updateUser(null)
    setAuthenticated(false)
  }

  return (
    <AuthContext.Provider value={{ ...contextState, evaluateAuth, logout }}>
      {isValidating ? <LoadingPage /> : children}
    </AuthContext.Provider>
  )
}
