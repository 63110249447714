import { FC } from 'react'
import { View, Wrapper } from './styles'

import loaderAnimationData from '../../assets/animations/loader.json'

import Lottie from 'react-lottie'
import { P1, H4 } from '../../styles'
import { useTranslation } from 'react-i18next'

export const LoadingPage: FC = () => {
  const { t } = useTranslation()
  return (
    <View>
      <Wrapper>
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: loaderAnimationData,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid',
            },
          }}
          height="20rem"
        />

        <H4 isBold>{t('loading.title')}</H4>
        <P1 isBold>{t('loading.description')}</P1>
      </Wrapper>
    </View>
  )
}
