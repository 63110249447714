import { FC, useEffect } from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from '../contexts/auth'
import { HomePage } from '../pages/Home'
import { LoginPage } from '../pages/Login'
import { NotFoundPage } from '../pages/NotFound'
import { RedirectToLogin } from '../pages/RedirectToLogin'

export const AppRouter: FC = () => {
  const { search, pathname } = useLocation()
  const navigate = useNavigate()

  const { isAuthenticated, evaluateAuth } = useAuth()

  /**
   * Toda vez que tiver um token na URL, salva no localStorage e remove ele da URL
   */
  useEffect(() => {
    const searchParams = new URLSearchParams(search)
    const token = searchParams.get('auth')

    if (token) {
      localStorage.setItem('RCSP_ACCESS_TOKEN', token)
      evaluateAuth()

      searchParams.delete('auth')
      let to = pathname

      if (searchParams.toString().length) {
        to = `${to}?${searchParams.toString()}`
      }

      navigate(to, { replace: true })
    }
  }, [search])

  return (
    <Routes>
      {!isAuthenticated ? (
        <>
          <Route path="/entrar" element={<LoginPage />} />
          <Route path="*" element={<RedirectToLogin />} />
        </>
      ) : (
        <>
          <Route path="/entrar" element={<LoginPage />} />
          <Route path="/" element={<HomePage />} />
          <Route path="*" element={<NotFoundPage />} />
        </>
      )}
    </Routes>
  )
}
