export const Colors = {
  primary: {
    _000: '#13bf1b',
    _100: '#D36600',
    _200: '#B75800',
  },

  secondary: '#420080',

  gray: {
    _000: '#FFFFFF',
    _100: '#F9F9F9',
    _200: '#E9EEF3',
    _300: '#E0E5EE',
    _400: '#B6BACD',
    _500: '#7F859D',
    _600: '#414251',
  },

  status: {
    error: '#D74B29',
    success: '#00A28F',
    warning: '#FFB900',
  },

  support: {
    _000: '#420080',
    _100: '#350066',
    _200: '#CCEBFF',
    _300: '#0076DE',
  },

  chart: {
    _100: '#649EFF',
    _200: '#6FD4FF',
  },
}
