import styled, { css } from 'styled-components'
import { Breakpoints, Colors, easing, Typography } from '../../styles'

interface TrProps {
  isDisabled: boolean
}

export const TR = styled.tr<TrProps>`
  transition: all 0.3s ${easing.basic};
  background-color: ${({ isDisabled }) =>
    isDisabled ? Colors.gray._200 : Colors.gray._000};
  ${({ isDisabled }) =>
    !isDisabled &&
    css`
      cursor: pointer;
    `}
  margin-top: 2rem;
`

export interface TdProps {
  size?: 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl'
  align?: 'left' | 'center' | 'right'
}

export const TD = styled.td<TdProps>`
  ${({ size }) =>
    size === 'xxs' &&
    css`
      width: 10%;
    `}
  ${({ size }) =>
    size === 'xs' &&
    css`
      width: 20%;
    `}
  ${({ size }) =>
    size === 'sm' &&
    css`
      width: 35%;
    `}
  ${({ size }) =>
    size === 'md' &&
    css`
      width: 55%;
    `}
  ${({ size }) =>
    size === 'lg' &&
    css`
      width: 65%;
    `}
  ${({ size }) =>
    size === 'xl' &&
    css`
      width: 80%;
    `}
  ${({ size }) =>
    size === 'xxl' &&
    css`
      width: 100%;
    `}

   ${({ align }) =>
    align === 'left' &&
    css`
      text-align: left;
    `}
  ${({ align }) =>
    align === 'center' &&
    css`
      text-align: center !important;
    `}
  ${({ align }) =>
    align === 'right' &&
    css`
      text-align: right;
    `}
`

export const View = styled.table`
  border-collapse: separate;
  border-spacing: 0 0.5rem;

  > thead > tr > ${TD} {
    color: ${Colors.gray._500};
    padding: 1.6rem 2rem;
    text-align: left;
    line-height: ${Typography.size.s3};
    font-size: ${Typography.size.s1};
  }

  > tbody > ${TR} > ${TD} {
    color: ${Colors.gray._600};
    padding: 0 2rem;
    height: 6rem;
    font-size: ${Typography.size.s2};
    line-height: ${Typography.size.s3};
    font-weight: ${Typography.weight.regular};
  }

  @media screen and (max-width: ${Breakpoints.sm}) {
    thead {
      display: none;
    }

    &,
    & tbody,
    & tr,
    & td {
      display: block;
      width: 100%;
      text-align: right;
      border-top: 0 !important;
    }

    tbody tr {
      ${TD} {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:last-of-type {
          justify-content: center;
          &::before {
            margin-right: 2rem;
          }
        }

        &::before {
          content: attr(data-label);
          font-weight: ${Typography.weight.bold};
        }
      }
    }
  }
`
