import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { H1 } from '../../styles'
import { View } from './styles'

export const NotFoundPage: FC = () => {
  const { t } = useTranslation()
  return (
    <View>
      <H1>
        404<span className="separator"></span>
        {t('page-not-found')}
      </H1>
    </View>
  )
}
