import { createReducer, IHandlers } from '../../helpers/createReducer'
import * as actions from './actions'

export interface IAuthContextState {
  isAuthenticated: boolean
  user: any
}

export const INITIAL_STATE: IAuthContextState = {
  isAuthenticated: false,
  user: null,
}

const handlers: IHandlers<IAuthContextState> = {
  [actions.UPDATE_USER]: (state = INITIAL_STATE, { payload }) => ({
    ...state,
    user: payload,
  }),
  [actions.SET_AUTHENTICATED]: (state = INITIAL_STATE, { payload }) => ({
    ...state,
    isAuthenticated: payload,
  }),
}

export const reducer = createReducer(INITIAL_STATE, handlers)
