import { FC, useState } from 'react'
import { Globe, ChevronDown, LogOut, ChevronUp, Menu, X } from 'react-feather'
import classNames from 'classnames'
import { Main, P2 } from '../../../styles'
import { View, Logo, UserProfile, Dropdown } from './styles'
import LogoWhite from '../../../assets/logo.svg'
import { Outlet } from 'react-router'
import { useAuth } from '../../../contexts/auth'
import { truncate } from '../../../helpers/functions'
import { useTranslation } from 'react-i18next'
import { Profile } from '../../profile'
import languages from './languages.json'

export const Head: FC = () => {
  const [isMenuOpen, setMenuOpen] = useState(false)
  const [showLanguage, onShowLanguage] = useState(false)
  const { t, i18n } = useTranslation()
  const { isAuthenticated, logout, user } = useAuth()
  return (
    <Main>
      <View isAuthenticated={isAuthenticated} isMenuOpen={isMenuOpen}>
        <Logo to="/">
          <img src={LogoWhite} alt="" />
        </Logo>
        {isAuthenticated && (
          <>
            <UserProfile>
              <div className="whoami">
                <P2 isBold title={user?.name}>
                  {truncate(user?.name, 16)}
                </P2>
              </div>
              <button type="button">
                <Profile avatar={user?.name} />
                <Dropdown type="profile">
                  <P2 onClick={() => onShowLanguage(!showLanguage)}>
                    <span className="icon">
                      <Globe />
                    </span>
                    {t('head.actions.language')}
                    {showLanguage ? <ChevronUp /> : <ChevronDown />}
                  </P2>
                  <P2 className={showLanguage ? 'active' : undefined}>
                    {languages.map((lang) => (
                      <span
                        className={classNames('lang', {
                          active: lang.key === i18n.language,
                        })}
                        key={lang.key}
                        role="presentation"
                        onClick={() => {
                          i18n.changeLanguage(lang.key)
                        }}
                      >
                        {lang.label}
                      </span>
                    ))}
                  </P2>
                  <P2 onClick={() => logout()}>
                    <span className="icon">
                      <LogOut />
                    </span>
                    {t('head.actions.logout')}
                  </P2>
                </Dropdown>
              </button>
              <button
                type="button"
                className="toggle-menu"
                onClick={() => setMenuOpen((old) => !old)}
              >
                {isMenuOpen ? <X size="2rem" /> : <Menu size="2rem" />}
              </button>
            </UserProfile>
          </>
        )}
      </View>
      <Outlet />
    </Main>
  )
}
