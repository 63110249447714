import { createMuiTheme } from '@material-ui/core/styles'
import styled from 'styled-components'
import { Typography } from '../../styles'

export const ictsTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#420080',
    },
  },
})

export const View = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #9298aa;
  height: 100vh;
  width: 100%;

  .language-container {
    align-self: flex-end;
    padding: 20px 8px;
    > ul {
      display: flex;
      font-size: ${Typography.size.s3};
      font-weight: ${Typography.weight.semiBold};
      color: #878787;
      li {
        margin: 0 5px;
        width: 25px;
      }
      li.active,
      li:hover {
        color: #333;
        font-weight: ${Typography.weight.bold};
        cursor: pointer;
      }
    }
  }

  .card {
    background-color: #fafafa;
    width: 400px;
    padding: 50px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;

    > img {
      width: 225px;
      margin: 10px 0;
    }
  }
`
