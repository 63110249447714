import { FC, FormEventHandler, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { ThemeProvider } from '@material-ui/core/styles'
import ReCAPTCHA from 'react-google-recaptcha'

import { useAuth } from '../../contexts/auth'
import { ictsTheme, View } from './styles'

import availableLanguages from '../../components/layout/Head/languages.json'
import ictsNovoLogo from '../../assets/Aliant.png'
import { Typography } from '../../styles'
import { http } from '../../services/http'
import { toast } from 'react-toastify'

export const LoginPage: FC = () => {
  const [captchaToken, setCaptchaToken] = useState<string | null>(null)
  const [isSubmitting, setSubmitting] = useState(false)

  const formRef = useRef<HTMLFormElement>(null)

  const { t, i18n } = useTranslation()
  const { search } = useLocation()
  const navigate = useNavigate()
  const { isAuthenticated, evaluateAuth } = useAuth()

  useEffect(() => {
    if (isAuthenticated) {
      const to = new URLSearchParams(search).get('to')
      navigate(to ?? '/', { replace: true })
    }
  }, [isAuthenticated])

  const handleLoginSubmit: FormEventHandler<HTMLFormElement> = async (
    event,
  ) => {
    event.preventDefault()
    if (formRef.current !== null) {
      const formData = new FormData(formRef.current)
      const postData: any = {}
      for (const [fieldName, fieldValue] of formData.entries()) {
        postData[fieldName] = fieldValue
      }

      if (!postData.username || !postData.password) {
        toast.error(t('login.errors.missing-fields'), {
          hideProgressBar: true,
          closeButton: false,
          autoClose: 2000,
          style: {
            fontSize: Typography.size.s3,
          },
        })
        return
      }

      if (!captchaToken) {
        toast.error(t('login.errors.captcha-not-completed'), {
          hideProgressBar: true,
          closeButton: false,
          autoClose: 2000,
          style: {
            fontSize: Typography.size.s3,
          },
        })
        return
      }

      setSubmitting(true)
      try {
        const { data: response } = await http().post('/sessions', postData)
        localStorage.setItem('RCSP_ACCESS_TOKEN', response.access_token)
        evaluateAuth()
      } catch (err) {
        toast.error(
          err.response?.data.message ?? t('login.errors.unable-to-login'),
          {
            hideProgressBar: true,
            closeButton: false,
            autoClose: 2000,
            style: {
              fontSize: Typography.size.s3,
            },
          },
        )

        if (!err.response) {
          console.error(err)
        }
      } finally {
        setSubmitting(false)
      }
    }
  }

  const captchaLanguage: any = {
    pt: 'pt-BR',
    en: 'en',
    es: 'es',
  }

  return (
    <ThemeProvider theme={ictsTheme}>
      <View>
        <div className="language-container">
          <ul>
            {availableLanguages.map((lang) => (
              <li
                className={i18n.language === lang.key ? 'active' : undefined}
                key={lang.key}
                onClick={() => {
                  i18n.changeLanguage(lang.key)
                  window.location.reload()
                }}
              >
                {lang.label}
              </li>
            ))}
          </ul>
        </div>
        <form onSubmit={handleLoginSubmit} ref={formRef} className="card">
          <img src={ictsNovoLogo} alt="" />
          <TextField
            InputProps={{ style: { fontSize: Typography.size.s3 } }}
            InputLabelProps={{ style: { fontSize: Typography.size.s3 } }}
            label={t('login.username')}
            name="username"
            fullWidth
          />
          <TextField
            style={{ marginTop: '24px' }}
            InputProps={{ style: { fontSize: Typography.size.s3 } }}
            InputLabelProps={{ style: { fontSize: Typography.size.s3 } }}
            label={t('login.password')}
            type="password"
            name="password"
            fullWidth
          />
          <div style={{ marginTop: '24px' }}>
            <ReCAPTCHA
              sitekey={
                process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY ?? 'invalid key'
              }
              onChange={setCaptchaToken}
              hl={captchaLanguage[i18n.language]}
            />
          </div>
          <Button
            disabled={isSubmitting}
            type="submit"
            variant="contained"
            disableElevation
            size="large"
            color="primary"
            style={{
              fontSize: Typography.size.s3,
              borderRadius: 0,
              alignSelf: 'flex-end',
              marginTop: '24px',
            }}
          >
            {t('login.btn-label')}
          </Button>
        </form>
      </View>
    </ThemeProvider>
  )
}
