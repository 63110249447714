import { FC } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { AuthProvider } from './contexts/auth'
import { AppRouter } from './router'
import { GlobalStyles } from './styles'
import './i18n'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export const App: FC = () => {
  return (
    <AuthProvider>
      <BrowserRouter>
        <GlobalStyles />
        <AppRouter />
      </BrowserRouter>
      <ToastContainer />
    </AuthProvider>
  )
}
